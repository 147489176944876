<template>
    <div class="text-xs text-center" v-if="data">{{ $t('Version') }} {{ data.appVersion }}
        ({{ data.platform }}{{ data.bundleVersion ? ' - ' + data.bundleVersion : '' }})</div>
</template>

<script setup>

const bundle = useBundleStore()

const data = ref(null)

onMounted(() => {

    const { onResult, onError } = bundle.getVersion()

    onResult((result) => {
        data.value = result
    })

    onError((error) => {
        data.value = error
    })

})

</script>